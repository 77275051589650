<template>
  <div class="page">
    <div
      :class="['item', getClassName(item)]"
      v-for="(item, index) in listData"
      :key="index"
    >
      <!-- 标题信息 -->
      <div class="item-title">
        <div class="item-l">
          <img class="item-img" src="./img/icon.png" alt="" />
          <div class="line"></div>
          <span>{{ item.deviceName }}</span>
        </div>
        <div class="item-r">
          <!-- {{ "3.2km" }} -->
        </div>
      </div>
      <!-- 充电信息 -->
      <div class="content">
        <div class="content-l">
          <img :src="require(`${getImgPath(item)}`)" alt="" />
        </div>
        <div class="content-r">
          <div class="common">
            <span class="circle"></span>
            <span>{{ getStatusName(item) }}</span>
          </div>
          <div class="common">
            <span>电压(V) ：</span>
            <span>{{ item.dataVal.voltage }}</span>
          </div>
          <div class="common">
            <span>电流(A) ：</span>
            <span>{{ item.dataVal.current }}</span>
          </div>
          <div class="common">
            <span>功率(KW) ：</span>
            <span>{{ item.dataVal.currentcapacity }}</span>
          </div>
        </div>
      </div>
      <div class="cut-lin"></div>
      <!-- 状态 -->
      <div :class="['status', getStatusClassName(item)]">
        <div class="status-commont">
          <span>今日电量</span>
          <span class="txt">{{ item.dataVal.pcptoday }} 度</span>
        </div>
        <div class="status-commont">
          <span>今日电费</span>
          <span class="txt">{{ item.dataVal.electricitytoday }} 元</span>
        </div>
        <div class="status-commont">
          <span>超限状态</span>
          <span class="txt">{{
            item.dataVal.voverrunstate == "0" ? "正常" : "超限"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryChargePileReportDataURL } from "./api";

export default {
  name: "intelligentCharging",
  data() {
    return {
      listData: [],
    };
  },
  created() {
    this.getListData();
  },
  methods: {
    getListData() {
      this.$axios
        .get(queryChargePileReportDataURL, {
          params: {
            productId: "22a5925aa4f6c0777b5730de6eea915a",
          },
        })
        .then((res) => {
          if (res.code == 200 && res.success) {
            res.data.forEach((v) => {
              // 定义一个字段接受正常模式的数据
              v.dataVal = {};
              if (v.data) {
                v.data = JSON.parse(JSON.parse(v.data));
                const value =
                  v.data.value &&
                  v.data.value.split(";").map((v) => v.split(":"));
                if (value) {
                  value.forEach((ele) => {
                    v.dataVal[ele[0]] = ele[1];
                  });
                }
              }
            });
            this.listData = res.data;
            // console.log(JSON.stringify(this.listData));
          }
        });
    },
    getImgPath(item) {
      let path = "./img/offline.png";
      if (item.data) {
        let { isOnline, isUse } = item.data;
        if (isOnline == "1" && isUse == "1") {
          path = "./img/charging.gif";
        } else if (isOnline == "1" && isUse != "1") {
          path = "./img/rechargeable.png";
        }
      }
      return path;
    },
    getClassName(item) {
      let className = "default";
      if (item.data) {
        let { isOnline, isUse } = item.data;
        if (isOnline == "1" && isUse == "1") {
          className = "pendding";
        } else if (isOnline == "1" && isUse != "1") {
          className = "free";
        }
      }
      return className;
    },
    getStatusName(item) {
      let name = "已离线";
      if (item.data) {
        let { isOnline, isUse } = item.data;
        if (isOnline == "1" && isUse == "1") {
          name = "充电中";
        } else if (isOnline == "1" && isUse != "1") {
          name = "可充电";
        }
      }
      return name;
    },
    getStatusClassName(item) {
      let className = "default";
      if (item.dataVal) {
        const voverrunstate = item.dataVal.voverrunstate;
        // voverrunstate == '0' 表示正常
        className = voverrunstate == "0" ? "default" : "limit";
      }
      return className;
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100vw;
  min-height: 100vh;
  padding: 34px 28px;
  box-sizing: border-box;
  background: #f9f9f9;
  overflow-y: scroll;
  word-wrap: break-word;
  word-break: break-all;

  .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    box-sizing: border-box;
    min-height: 516px;
    padding: 30px 24px 22px 38px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;
    .item-title {
      display: flex;
      justify-content: space-between;
      .item-l {
        display: flex;
        align-items: center;
        .item-img {
          width: 32px;
          height: 34px;
        }
        .line {
          margin: 0 10px;
          width: 4px;
          height: 38px;
          background-color: #e6e6e6;
        }
        span {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
        }
      }
      .item-r {
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
      }
    }
    .content {
      display: flex;
      align-items: center;
      gap: 56px;
      margin-top: 38px;
      width: 100%;
      min-height: 210px;
      .content-l {
        width: 174px;
        min-width: 174px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content-r {
        flex: 1;
        display: flex;
        flex-direction: column;
        .common {
          width: 100%;
          // overflow: hidden;
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
          > span {
            &:first-child {
              white-space: nowrap;
            }
            margin-right: 5px;
          }
          .circle {
            display: block;
            margin-right: 14px;
            width: 16px;
            height: 16px;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
          }
          span {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
          }
        }
      }
    }
    .cut-lin {
      margin: 32px 0 22px 0;
      width: 100%;
      height: 2px;
      background: #e6e6e6;
    }
    .status {
      flex: 1;
      display: flex;
      .status-commont {
        padding: 0 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 4px solid #e6e6e6;
        &:last-child {
          border-right: none;
        }
        .txt {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
        }
        > span {
          display: inline-block;
          margin-bottom: 6px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
        }
      }
      &.limit {
        .status-commont {
          .txt {
            color: rgba(224, 32, 32, 1);
          }
        }
      }
    }
    &.pendding {
      .content .content-r .common .circle {
        background: rgba(247, 181, 0, 1);
      }
    }
    &.free {
      .content .content-r .common .circle {
        background: rgba(167, 230, 100, 1);
      }
    }
  }
}
</style>
